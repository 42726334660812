var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      [
        _vm.payload.isLivePagination
          ? _c(
              "v-card-title",
              { staticClass: "pa-1" },
              [
                _vm.payload.showScanCode
                  ? _c("barcode-scanner", {
                      attrs: {
                        search: _vm.search,
                        scannerPayload: _vm.scannerPayload,
                        loading: _vm.scannerFieldLoading,
                      },
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    })
                  : _c("v-text-field", {
                      staticClass: "pa-0",
                      attrs: {
                        solo: "",
                        label: _vm.$t("message.common.search"),
                        "hide-details": "",
                        dense: "",
                      },
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "v-card-title",
          { staticClass: "pa-0" },
          [
            _c(
              "v-btn",
              {
                attrs: { "x-small": "", fab: "", disabled: _vm.page === 1 },
                on: { click: _vm.prevPage },
              },
              [_c("v-icon", [_vm._v("mdi-chevron-left")])],
              1
            ),
            _vm.payload.addBtnTo &&
            _vm.checkActionPermission(_vm.payload.module, _vm.CONSTANTS.CREATE)
              ? _c(
                  "v-btn",
                  {
                    staticClass: "ml-1",
                    attrs: {
                      color: "primary",
                      fab: "",
                      "x-small": "",
                      id: "newbtnTo",
                      to: _vm.payload.addBtnTo,
                    },
                  },
                  [_c("v-icon", [_vm._v("mdi-plus")])],
                  1
                )
              : _vm._e(),
            _c("v-spacer"),
            _c("h5", [
              _vm._v(
                _vm._s(
                  _vm.payload.isCustomModules
                    ? _vm.payload.moduleTitle
                    : _vm.$t(_vm.payload.moduleTitle)
                )
              ),
            ]),
            _c("v-spacer"),
            _c("span", { staticClass: "mr-2 subtitle-2" }, [
              _vm._v(
                _vm._s(
                  _vm.payload.total > 0
                    ? _vm.paginationInfo +
                        " " +
                        _vm.$t("message.common.of") +
                        " " +
                        _vm.payload.total
                    : ""
                )
              ),
            ]),
            _c(
              "v-btn",
              {
                attrs: {
                  "x-small": "",
                  fab: "",
                  disabled: _vm.payload.hasNoNext,
                },
                on: { click: _vm.nextPage },
              },
              [_c("v-icon", [_vm._v("mdi-chevron-right")])],
              1
            ),
          ],
          1
        ),
      ],
      _c(
        "v-card-text",
        { staticClass: "pa-1 pt-0 mt-2 mb-4" },
        [
          _vm.payload.loading
            ? _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c("v-progress-linear", {
                    attrs: {
                      active: _vm.payload.loading,
                      indeterminate: _vm.payload.loading,
                      color: "primary",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.payload.itemHeaders && _vm.payload.list && _vm.payload.list.length
            ? _c(
                "v-card",
                {
                  staticClass: "pa-0 mt-0",
                  attrs: { outlined: "", color: "#e8eef0" },
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-0" },
                    [
                      _vm._l(_vm.payload.itemHeaders, function (header, index) {
                        return [
                          header.labels
                            ? _c(
                                "v-col",
                                {
                                  key: index,
                                  staticClass: "pa-1",
                                  attrs: { cols: header.cols },
                                },
                                [
                                  header.labels
                                    ? _c(
                                        "v-row",
                                        { staticClass: "ma-0" },
                                        _vm._l(
                                          header.labels,
                                          function (label, labelIndex) {
                                            return _c(
                                              "v-col",
                                              {
                                                key: labelIndex,
                                                staticClass: "pa-0",
                                              },
                                              [
                                                _c("strong", [
                                                  _vm._v(_vm._s(_vm.$t(label))),
                                                ]),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : typeof header === "string"
                            ? _c(
                                "v-col",
                                {
                                  key: index,
                                  class:
                                    _vm.payload.items[index] &&
                                    _vm.payload.items[index].class
                                      ? _vm.payload.items[index].class
                                      : "",
                                  attrs: {
                                    cols:
                                      _vm.payload.items[index] &&
                                      _vm.payload.items[index].cols
                                        ? _vm.payload.items[index].cols
                                        : "",
                                  },
                                },
                                [_c("strong", [_vm._v(_vm._s(_vm.$t(header)))])]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.payload.list, function (item, listIndex) {
            return [
              _c(
                "v-card",
                {
                  key: listIndex,
                  staticClass: "pa-0 py-1 mt-1",
                  attrs: { outlined: "", color: "#e8eaf6" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.checkActionPermission(
                        _vm.payload.module,
                        _vm.CONSTANTS.EDIT
                      )
                        ? _vm.payload.editHandler(item)
                        : ""
                    },
                  },
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pa-0 py-0" },
                    [
                      _c(
                        "v-row",
                        [
                          [
                            _vm._l(
                              _vm.payload.items,
                              function (colValues, index) {
                                return [
                                  colValues.value
                                    ? [
                                        _c(
                                          "v-col",
                                          {
                                            key: index,
                                            class: colValues.class,
                                            attrs: { cols: colValues.cols },
                                          },
                                          [
                                            colValues.projectColor &&
                                            item[colValues.colorValue]
                                              ? _c("div", {
                                                  staticClass: "mr-1",
                                                  style:
                                                    "float:left; width: 20px; height: 20px;background-color: " +
                                                    item[colValues.colorValue] +
                                                    ";",
                                                })
                                              : _vm._e(),
                                            colValues.isParagraph
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "custom-height",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item[colValues.value]
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "span",
                                                  {
                                                    class: colValues.spanClass,
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item[colValues.value]
                                                      )
                                                    ),
                                                  ]
                                                ),
                                          ]
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "v-col",
                                          {
                                            key: index,
                                            class: colValues.class,
                                            attrs: { cols: colValues.cols },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              { class: colValues.rowClass },
                                              [
                                                _vm._l(
                                                  colValues.subItems,
                                                  function (subitems, i) {
                                                    return [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          key: i,
                                                          class: subitems.class,
                                                          attrs: {
                                                            cols: subitems.cols,
                                                          },
                                                        },
                                                        [
                                                          subitems.value ===
                                                          "is_completed"
                                                            ? [
                                                                _vm.payload
                                                                  .moduleTitle ===
                                                                "message.layout.drawing"
                                                                  ? _c(
                                                                      "v-select",
                                                                      {
                                                                        staticClass:
                                                                          "px-0 pr-2",
                                                                        attrs: {
                                                                          dense:
                                                                            "",
                                                                          "hide-details":
                                                                            "",
                                                                          "item-text":
                                                                            "text",
                                                                          "item-value":
                                                                            "value",
                                                                          items:
                                                                            subitems.items,
                                                                          label:
                                                                            "Status",
                                                                          outlined:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                            },
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.saveStatusUpdate(
                                                                                listIndex
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            item[
                                                                              subitems
                                                                                .value
                                                                            ],
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                item,
                                                                                subitems.value,
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "item[subitems.value]",
                                                                        },
                                                                      }
                                                                    )
                                                                  : [
                                                                      !_vm
                                                                        .payload
                                                                        .itemHeaders
                                                                        ? _c(
                                                                            "span",
                                                                            {
                                                                              class:
                                                                                subitems.spanClass,
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "strong",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "message.projects.status"
                                                                                      )
                                                                                    ) +
                                                                                      ":"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      !_vm
                                                                        .payload
                                                                        .itemHeaders
                                                                        ? _c(
                                                                            "br"
                                                                          )
                                                                        : _vm._e(),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          class:
                                                                            subitems.spanClass,
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item[
                                                                                subitems
                                                                                  .value
                                                                              ] ===
                                                                                1
                                                                                ? _vm.$t(
                                                                                    "message.customModule.completed"
                                                                                  )
                                                                                : _vm.$t(
                                                                                    "message.customModule.notCompleted"
                                                                                  )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                              ]
                                                            : subitems.isTranslatable
                                                            ? [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    class:
                                                                      subitems.spanClass,
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          item[
                                                                            subitems
                                                                              .value
                                                                          ]
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            : _c(
                                                                "span",
                                                                {
                                                                  class:
                                                                    subitems.spanClass,
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item[
                                                                        subitems
                                                                          .value
                                                                      ]
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                        ],
                                                        2
                                                      ),
                                                    ]
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                ]
                              }
                            ),
                          ],
                          _vm.payload.actionSize
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pa-0 pr-1 my-auto",
                                  attrs: { cols: _vm.payload.actionSize },
                                },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      staticClass: "mx-0",
                                      attrs: {
                                        row: "",
                                        wrap: "",
                                        "justify-center": "",
                                      },
                                    },
                                    [
                                      !_vm.payload.isChecklist &&
                                      _vm.payload.hasDelete &&
                                      _vm.checkActionPermission(
                                        _vm.payload.module,
                                        _vm.CONSTANTS.DELETE
                                      )
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                "max-height": "24",
                                                "max-width": "24",
                                                icon: "",
                                                color: "error",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.deleteRecord(
                                                    item.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-delete-circle"),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          }),
          !_vm.payload.loading && _vm.payload.list.length === 0
            ? _c(
                "v-alert",
                {
                  staticClass: "mt-2",
                  attrs: { prominent: "", text: "", type: "info" },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", { staticClass: "grow" }, [
                        _vm._v(_vm._s(_vm.$t("message.common.noResults"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }